import React from 'react';
import './App.css';
import { BrowserRouter,HashRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Login from './components/login-signup/login.component';
import Signup from "./components/login-signup/signup.component"
import Disclaimer from './pages/Disclaimer';
import Deposit from './components/deposit-withdraw/Deposit';
import Withdraw from './components/deposit-withdraw/Withdraw';
import Help from './components/deposit-withdraw/Help';
import Plans from './components/Plans/Plans';
import { Toaster } from 'react-hot-toast';
import DepositProof from './components/deposit-withdraw/DepositProof';
import History from './components/deposit-withdraw/History';
import Subscription from './pages/Subscription';
import PrivateRoute from './PrivateRoutes';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermCondition from './pages/TermCondition';


function App() {
  return (
    <>
      <HashRouter>
        <Toaster />

        <Routes>
        
        <Route path="/" element={<Home />} />
         <Route path="/sign-in" element={<Login/>} />
         <Route path="/sign-up" element={<Signup/>} />
         <Route path="/profile" element={<PrivateRoute Component={Profile}/>} />
         <Route path="/disclaimer" element={<PrivateRoute Component={Disclaimer}/>} />
         <Route path="/deposit" element={<PrivateRoute Component={Deposit}/>} />
         <Route path="/deposit-proof" element={<PrivateRoute Component={DepositProof}/>} />
         <Route path="/withdraw" element={<PrivateRoute Component={Withdraw}/>} />
         <Route path="/subscription-plans" element={<PrivateRoute Component={Subscription}/>} />
         {/* <Route path="/profile" element={<Profile/>} />
         <Route path='/disclaimer' element={ <Disclaimer/> }/>
         <Route path='/deposit' element={ <Deposit/> } />
         <Route path='/deposit-proof' element={ <DepositProof/> } />
         <Route path='/withdraw' element={ <Withdraw/> } /> 
         <Route path='/subscription-plans' element={ <Subscription/> } /> */}
         <Route path='/help' element={<Help/>} />
         <Route path='/plans' element={ <Plans/> } />
         <Route path='/history' element={ <History/> } />
         <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
         <Route path='/term-and-condition' element={<TermCondition/>}/>
       
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
