import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Login from "./components/login-signup/login.component";
import { AuthContext } from "./AuthProvider";

export default function PrivateRoute(props) {
  const navigate = useNavigate();
  const { userToken } = useContext(AuthContext);

  const { Component } = props;

  useEffect(() => {
    const getToken = Cookies.get("userToken");
    if (!getToken) {
      navigate("/sign-in");
    }
  }, []);

  return (
    <>
      {userToken ? <Component /> : <Login />}

      {/*  */}
    </>
  );
}
