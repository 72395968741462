import React from "react";

function TermCondition() {
  return (
    <>
      <div className="container">
        <h2 class="font-weight-normal text-7 mb-2">
          Terms & <strong class="font-weight-extra-bold">Conditions</strong>
        </h2>
        <p>Welcome to WaliExchange!</p>

        <p>
          These terms and conditions outline the rules and regulations for the
          use of WaliExchange's Website, located at https://www.WaliExchange.com
        </p>

        <p>
          By accessing this website we assume you accept these terms and
          conditions. Do not continue to use WaliExchange if you do not agree to
          take all of the terms and conditions stated on this page.
        </p>

        <p>
          The following terminology applies to these Terms and Conditions,
          Privacy Statement and Disclaimer Notice and all Agreements: "Client",
          "You" and "Your" refers to you, the person log on this website and
          compliant to the Company�s terms and conditions. "The Company",
          "Ourselves", "We", "Our" and "Us", refers to our Company. "Party",
          "Parties", or "Us", refers to both the Client and ourselves. All terms
          refer to the offer, acceptance and consideration of payment necessary
          to undertake the process of our assistance to the Client in the most
          appropriate manner for the express purpose of meeting the Client�s
          needs in respect of provision of the Company�s stated services, in
          accordance with and subject to, prevailing law of Netherlands. Any use
          of the above terminology or other words in the singular, plural,
          capitalization and/or he/she or they, are taken as interchangeable and
          therefore as referring to same.
        </p>

        <h3>
          <strong>Cookies</strong>
        </h3>

        <p>
          We employ the use of cookies. By accessing WaliExchange, you agreed to
          use cookies in agreement with the WaliExchange's Privacy Policy.
        </p>

        <p>
          Most interactive websites use cookies to let us retrieve the user�s
          details for each visit. Cookies are used by our website to enable the
          functionality of certain areas to make it easier for people visiting
          our website. Some of our affiliate/advertising partners may also use
          cookies.
        </p>

        <h3>
          <strong>License</strong>
        </h3>

        <p>
          Unless otherwise stated, WaliExchange and/or its licensors own the
          intellectual property rights for all material on WaliExchange. All
          intellectual property rights are reserved. You may access this from
          WaliExchange for your own personal use subjected to restrictions set
          in these terms and conditions.
        </p>

        <p>You must not:</p>
        <ul>
          <li>Republish material from WaliExchange</li>
          <li>Sell, rent or sub-license material from WaliExchange</li>
          <li>Reproduce, duplicate or copy material from WaliExchange</li>
          <li>Redistribute content from WaliExchange</li>
        </ul>

        <p>This Agreement shall begin on the date hereof.</p>

        <p>
          Parts of this website offer an opportunity for users to post and
          exchange opinions and information in certain areas of the website.
          WaliExchange does not filter, edit, publish or review Comments prior
          to their presence on the website. Comments do not reflect the views
          and opinions of WaliExchange,its agents and/or affiliates. Comments
          reflect the views and opinions of the person who post their views and
          opinions. To the extent permitted by applicable laws, WaliExchange
          shall not be liable for the Comments or for any liability, damages or
          expenses caused and/or suffered as a result of any use of and/or
          posting of and/or appearance of the Comments on this website.
        </p>

        <p>
          WaliExchange reserves the right to monitor all Comments and to remove
          any Comments which can be considered inappropriate, offensive or
          causes breach of these Terms and Conditions.
        </p>

        <p>You warrant and represent that:</p>

        <ul>
          <li>
            You are entitled to post the Comments on our website and have all
            necessary licenses and consents to do so;
          </li>
          <li>
            The Comments do not invade any intellectual property right,
            including without limitation copyright, patent or trademark of any
            third party;
          </li>
          <li>
            The Comments do not contain any defamatory, libelous, offensive,
            indecent or otherwise unlawful material which is an invasion of
            privacy
          </li>
          <li>
            The Comments will not be used to solicit or promote business or
            custom or present commercial activities or unlawful activity.
          </li>
        </ul>

        <p>
          You hereby grant WaliExchange a non-exclusive license to use,
          reproduce, edit and authorize others to use, reproduce and edit any of
          your Comments in any and all forms, formats or media.
        </p>

        <h3>
          <strong>Hyperlinking to our Content</strong>
        </h3>

        <p>
          The following organizations may link to our Website without prior
          written approval:
        </p>

        <ul>
          <li>Government agencies;</li>
          <li>Search engines;</li>
          <li>News organizations;</li>
          <li>
            Online directory distributors may link to our Website in the same
            manner as they hyperlink to the Websites of other listed businesses;
            and
          </li>
          <li>
            System wide Accredited Businesses except soliciting non-profit
            organizations, charity shopping malls, and charity fundraising
            groups which may not hyperlink to our Web site.
          </li>
        </ul>

        <p>
          These organizations may link to our home page, to publications or to
          other Website information so long as the link: (a) is not in any way
          deceptive; (b) does not falsely imply sponsorship, endorsement or
          approval of the linking party and its products and/or services; and
          (c) fits within the context of the linking party�s site.
        </p>

        <p>
          We may consider and approve other link requests from the following
          types of organizations:
        </p>

        <ul>
          <li>commonly-known consumer and/or business information sources;</li>
          <li>dot.com community sites;</li>
          <li>associations or other groups representing charities;</li>
          <li>online directory distributors;</li>
          <li>internet portals;</li>
          <li>accounting, law and consulting firms; and</li>
          <li>educational institutions and trade associations.</li>
        </ul>

        <p>
          We will approve link requests from these organizations if we decide
          that: (a) the link would not make us look unfavorably to ourselves or
          to our accredited businesses; (b) the organization does not have any
          negative records with us; (c) the benefit to us from the visibility of
          the hyperlink compensates the absence of WaliExchange; and (d) the
          link is in the context of general resource information.
        </p>

        <p>
          These organizations may link to our home page so long as the link: (a)
          is not in any way deceptive; (b) does not falsely imply sponsorship,
          endorsement or approval of the linking party and its products or
          services; and (c) fits within the context of the linking party�s site.
        </p>

        <p>
          If you are one of the organizations listed in paragraph 2 above and
          are interested in linking to our website, you must inform us by
          sending an e-mail to WaliExchange. Please include your name, your
          organization name, contact information as well as the URL of your
          site, a list of any URLs from which you intend to link to our Website,
          and a list of the URLs on our site to which you would like to link.
          Wait 2-3 weeks for a response.
        </p>

        <p>Approved organizations may hyperlink to our Website as follows:</p>

        <ul>
          <li>By use of our corporate name; or</li>
          <li>By use of the uniform resource locator being linked to; or</li>
          <li>
            By use of any other description of our Website being linked to that
            makes sense within the context and format of content on the linking
            party�s site.
          </li>
        </ul>

        <p>
          No use of WaliExchange's logo or other artwork will be allowed for
          linking absent a trademark license agreement.
        </p>

        <h3>
          <strong>iFrames</strong>
        </h3>

        <p>
          Without prior approval and written permission, you may not create
          frames around our Webpages that alter in any way the visual
          presentation or appearance of our Website.
        </p>

        <h3>
          <strong>Content Liability</strong>
        </h3>

        <p>
          We shall not be hold responsible for any content that appears on your
          Website. You agree to protect and defend us against all claims that is
          rising on your Website. No link(s) should appear on any Website that
          may be interpreted as libelous, obscene or criminal, or which
          infringes, otherwise violates, or advocates the infringement or other
          violation of, any third party rights.
        </p>

        <h3>
          <strong>Your Privacy</strong>
        </h3>

        <p>Please read Privacy Policy</p>

        <h3>
          <strong>Reservation of Rights</strong>
        </h3>

        <p>
          We reserve the right to request that you remove all links or any
          particular link to our Website. You approve to immediately remove all
          links to our Website upon request. We also reserve the right to amen
          these terms and conditions and it�s linking policy at any time. By
          continuously linking to our Website, you agree to be bound to and
          follow these linking terms and conditions.
        </p>

        <h3>
          <strong>Removal of links from our website</strong>
        </h3>

        <p>
          If you find any link on our Website that is offensive for any reason,
          you are free to contact and inform us any moment. We will consider
          requests to remove links but we are not obligated to or so or to
          respond to you directly.
        </p>

        <p>
          We do not ensure that the information on this website is correct, we
          do not warrant its completeness or accuracy; nor do we promise to
          ensure that the website remains available or that the material on the
          website is kept up to date.
        </p>

        <h3>
          <strong>Disclaimer</strong>
        </h3>

        <p>
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties and conditions relating to our website and
          the use of this website. Nothing in this disclaimer will:
        </p>

        <ul>
          <li>
            limit or exclude our or your liability for death or personal injury;
          </li>
          <li>
            limit or exclude our or your liability for fraud or fraudulent
            misrepresentation;
          </li>
          <li>
            limit any of our or your liabilities in any way that is not
            permitted under applicable law; or
          </li>
          <li>
            exclude any of our or your liabilities that may not be excluded
            under applicable law.
          </li>
        </ul>

        <p>
          The limitations and prohibitions of liability set in this Section and
          elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the
          disclaimer, including liabilities arising in contract, in tort and for
          breach of statutory duty.
        </p>

        <p>
          As long as the website and the information and services on the website
          are provided free of charge, we will not be liable for any loss or
          damage of any nature.
        </p>
        <p><em>I understand that Wali Exchange LLC Program will prepare me to actively trade equities for my own account. I understand that this course is not preparation to be a Licensed Broker in the financial industry or will help me get a job.</em></p>
<p><em>The Wali Exchange LLC Program should not be construed as a recommendation or an offer to buy or sell any security or the suitability of any investment strategy for a student. The purchase, sale, or advice regarding any security, other financial instrument or system can only be performed by a licensed Industry representative; such as, but not limited to a Broker/Dealer, Introducing Broker, FCM and and/or Registered Investment Advisor. Neither Wali Exchange LLC nor its representatives are licensed to make&nbsp;</em><em>your such</em><em>&nbsp;</em>advisements. All purchasers of the Wali Exchange LLC Program or other Wali Exchange LLC Remote Wali Exchange LLC products are encouraged to speak with a licensed representative of their choice regarding the appropriateness of investing/trading or of any particular investment/trading strategy.</p>
<p>&nbsp;</p>
<p><strong><span>ABOUT US:-</span></strong></p>
<p><span>Wali Exchange is a friendly and inspiring self-custodial crypto investment wallet</span><span>&nbsp;which</span><span>&nbsp;allows you to easily&nbsp;</span><span>store</span><span>&nbsp;receive and withdraw Bitcoin.</span></p>
<p><span>You can also safely deposit B</span><span>itcoin and purchase a trading plan, earn lucratively, withdraw your fun</span><span>d and be financially buoyant!!!</span></p>
<p>&nbsp;</p>
<p><strong><span>USERS LEGAL AGREEMENTS:-</span></strong></p>
<p><span>Welcome to&nbsp;</span><strong><span>Wali Exchange</span></strong><span>, this</span><span>&nbsp;user agreement is a contract between you and&nbsp;</span><span>wali exchange and b</span><span>y opening and using this account you&rsquo;re at&nbsp;</span><span>least&nbsp;</span><strong><span>18 years old</span></strong><span>&nbsp;and you agree to comply with all of the terms and con</span><span>ditions in this user agreement.&nbsp;</span><span>Also, you agree to comply with the following additional policies and each of the other agreements.</span></p>
<p><strong><span>CREATING AN ACCOUNT:-</span></strong></p>
<p>&nbsp;</p>
<p><span>Users are responsible for maintaining adequate security and control of their account and keeping their passwords</span><span>,</span><span>&nbsp;mailing address, personal information or any other codes that you use to access your wali exchange service</span><span>&nbsp;safe</span><span>.</span></p>
<p>&nbsp;</p>
<p><strong><span>HOLDING YOUR BALANCE:-</span></strong></p>
<p><span>Any</span><span>&nbsp;balance you hold in your WALI</span><span>&nbsp;account represents a</span><span>n unsecured claim against our service. Y</span><span>our&nbsp;</span><span>balance is combined&nbsp;</span><span>with the balances of other users and invests those funds in liquid investments. These pooled amount</span><span>s are held separate from wali&rsquo;s corporate funds, and we</span><span>&nbsp;will neither use these funds for its operating expenses or any other corporate purposes nor will it voluntarily make these funds available to its creditors in the event of bankruptcy. You will not receive interest or other earnings on the amounts i</span><span>n your&nbsp;</span><span>main&nbsp;</span><span>balance. You agree Wali Exchange</span><span>shall own the interest or other earnings on thes</span><span>e investments. However, User</span><span>s will only receive interest in accordance with&nbsp;</span><span>the amount pulled for trade by selecting a desired trading package. Using our medium, the fund shall be used</span><span>&nbsp;for trade which gives 99% success rate and 30% profit margin for the user over the trade duration.</span></p>
<p>&nbsp;</p>
<p><strong><span>ADDING FUNDS TO YOUR BALANCE:-</span></strong></p>
<p><span>Users are meant to use the payment methods of our service by copying or scanning Wali Exchange central Bitcoin</span><span>&nbsp;</span><span>address to make deposit into the&nbsp;</span><span>account</span><a name="withdrawing-money"></a><span>&nbsp;from any desired crypto wallet</span><span>&nbsp;</span><span>while an&nbsp;</span><strong><span>8% service fee</span></strong><span>&nbsp;will be</span><span>&nbsp;applied</span><span>.&nbsp;</span><strong><span>Note:&nbsp;</span></strong><span>Only Bitcoin are receivable and the service will automatically convert the asset into USDT</span><strong><span>&nbsp;</span></strong><span>in regards to&nbsp;</span><strong><span>Coinbase</span></strong><strong><span>&nbsp;</span></strong><span>exchange rate</span></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong><span>WITHDRAWING FROM YOUR ACCOUNT:-</span></strong></p>
<p>&nbsp;</p>
<p><span>Users are to log into their account&nbsp;</span><span>and enter their Bitcoin wallet address to receive payment while an&nbsp;</span><strong><span>8% service fee</span></strong><span>&nbsp;is applied on all withdraw.&nbsp;</span><span>Generall</span><span>y, we will send physical notification template</span><span>&nbsp;through the&nbsp;</span><span>user mail only to confirm the transaction. Withdraw request will be reviewed and confirmed in less than&nbsp;</span><span>12hrs,</span><span>&nbsp;If transaction is not approved or delayed</span><span>, kindly reach out to</span><span>&nbsp;</span><a href="Waliexchangehelpdesk@gmail.com"><u><span>Waliexchangehelpdesk@gmail.com</span></u></a></p>
<p>&nbsp;</p>
<p><strong><span>RESTRICTED ACTIVITIES AND POSSIBLE ACTIONS TO TAKE BY OUR SERVICE:-</span></strong></p>
<p>&nbsp;</p>
<p><span>In connection with your use of our service, your wali account, the wali services, or in the course of your interactions with wali support team, you will not;</span></p>
<p><span>Breach this user agreement between you and Wali Exchange.</span></p>
<p>&nbsp;</p>
<div>&bull;&nbsp;<span>Infringe Wa</span><span>l</span><span>i</span><span>&apos;s or any third party&apos;s copyright, patent, trademark, trade secret or other intellectual property rights, or rights of publicity or privacy.</span></div>
<div>&bull;&nbsp;<span>Act in a manner that is defamatory, trade libelous, threatening or harassing.</span></div>
<div>&bull;&nbsp;<span>Provide false, inaccurate or misleading information.</span></div>
<div>&bull;&nbsp;<span>Send or receive what we reasonably believe to be potentially fraudulent funds.</span></div>
<div>&bull;&nbsp;<span>Engage in potentially fraudulent or suspicious activity and/or transactions.</span></div>
<div>&bull;&nbsp;<span>Refuse to cooperate in an investigation or provide confirmation of your identity or an</span><span>y information we might later require.</span></div>
<div>&bull;&nbsp;<span>Allow your wali</span><span>&nbsp;account to have a negative balance.</span></div>
<div>&bull;&nbsp;<span>Take any action that may cause us to lose any of the services from our Internet service providers, payment processors, or other suppliers or service providers.</span></div>
<div>&bull;&nbsp;<span>Using Wali Exchange account</span><span>&nbsp;</span><span>with&nbsp;</span><span>information that is</span><span>&nbsp;not your own (e.g. name, phone number, email address, etc.)</span></div>
<div>&bull;&nbsp;<span>Harass and/or threaten our employees, agents, or other users.</span></div>
<div>&bull;&nbsp;<span>Reveal your account password(s) to anyone else, nor use anyone else&apos;s password. We are not responsible for losses incurred by you including, without limitation, the use of your account by any person other than you, arising as the result of misuse of passwords.</span></div>
<p>&nbsp;</p>
<p><strong><span>IF WE BELIEVE THAT YOU&rsquo;VE ENGAGED IN ANY OF THESE ACTIVITIES, WE MAY TAKE NUMBERS OF THESE ACTIVITIES, WE MAY TAKE A NUMBER OF ACTONS TO PROTECT WALI EXCHANGE, ITS CUSTOMERS AND OTHERS AT ANY TIME IN OUR SOLE DISCRETION. THE ACTIONS WE MAY TAKE INCLUDE, BUT ARE NOT LIMITED TO THE FOLLOWING;</span></strong></p>
<p>&nbsp;</p>
<div>&bull;&nbsp;<span>Terminate this u</span><span>ser agreement, limit your</span><span>&nbsp;account, and</span><span>/or close or suspend your</span><span>&nbsp;account, immedi</span><span>ately and without penalty to us.</span></div>
<div>&bull;&nbsp;<span>Refuse to provide the&nbsp;</span><span>services to you now and in the future;</span></div>
<p>&nbsp;</p>
<div>&bull;&nbsp;<span>Limit your access to our websites, software, systems (including any networks and servers u</span><span>sed to provide any of the wali</span><span>&nbsp;services) operated by&nbsp;</span><span>us or on our behalf, your wali account or any of the</span><span>&nbsp;services, including limiting your ability&nbsp;</span><span>to withdraw or make any transaction from the accoun</span><span>t</span><span>.</span></div>
<p>&nbsp;</p>


      </div>
    </>
  );
}

export default TermCondition;
