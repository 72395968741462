import { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [userToken, setUserToken] = useState(null);

  useEffect(() => {
    const Token = Cookies.get("userToken");

    if (Token) {
      setUserToken(Token);
    }
  }, []);

  return (
    <>
      <AuthContext.Provider value={{ userToken, setUserToken }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthProvider;
