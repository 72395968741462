import React, { useState } from 'react'
import Footer from '../footer/Footer'
import Navbar from '../navbar/Navbar'
import './deposit-withdraw.css'
import axios from 'axios'
import { toast } from 'react-hot-toast'

const DepositProof = () => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [previewUrl, setPreviewUrl] = useState(null)
  const [amount, setAmount] = useState('')
  const [loading, setLoading] = useState('')

  const fileInputRef = React.createRef()

  const handleFileInputChange = (event) => {
    const file = event.target.files[0]
    setSelectedFile(file)
    previewFile(file)
  }

  const handleDrop = (event) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    setSelectedFile(file)
    previewFile(file)
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const previewFile = (file) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      setPreviewUrl(reader.result)
    }
  }

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()

    if (!amount) {
      toast.error('Please fill up all the fields')
      return
    }

    try {
      const formData = new FormData()
      formData.append('cust_id', JSON.parse(localStorage.getItem('waliexchange'))?.cust_id ?? '1')
      formData.append('deposit_amt', amount)

      var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://waliexchange.com/api2/public/index.php/wx_deposit_wallet',
        data: formData,
      }
      const response = await axios(config)
      if (response.data.status === 200) {
        toast.success(response.data.msg)
      }
    } catch (err) {
      console.log(err)
      toast.error('There was an error while depositing...')
    }

    setLoading(false)
  }

  return (
    <>
      <Navbar />
      <div className="deposit-proof">
        <div className="container mt-4">
          <h3 className="text-center">Payment Made ? Kindly Add Prove Of Payment.</h3>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-10 my-4 mx-auto">
              <form onSubmit={handleSubmit} className="form-deposit">
                <div className="image-uploader form-div mx-auto text-center w-100" onDrop={handleDrop} onDragOver={handleDragOver}>
                  <input type="file" accept="image/*" ref={fileInputRef} onChange={handleFileInputChange} style={{ display: 'none' }} />
                  <div className="image-preview align-middle m-auto" onClick={() => fileInputRef.current.click()}>
                    {previewUrl ? <img src={previewUrl} alt="Preview" /> : <span className="text-white align-middle my-auto">Drop or click to select image</span>}
                  </div>
                </div>

                <div className="row mt-3 justify-content-between">
                  <div className="col-lg-4 col-md-4 col-6 order-lg-1 order-md-1 order-2">
                    <input className="mb-0" type="date" placeholder="Select Deposit Date" />
                    <p className="my-0 small px-2">Date Of Payment</p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12 order-lg-2 order-md-2 order-1">
                    <input type="text" placeholder="$ Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                  </div>
                  <div className="col-lg-4 col-md-4 col-6 order-lg-3 order-md-3 order-3 text-end">
                    <button type="submit" disabled={loading} className="mx-auto btn-blue border-0 px-5 py-1">
                      Confirm
                    </button>
                  </div>
                </div>
              </form>
              <p className="mt-3">Deposit will be reviewed and processed within 12hrs. Please be patient!</p>
              <p>
                If account not credited after deposit, kindly reach out to
                <a className="text-blue fw-bold" href="mailto:Waliexchangehelpdesk@gmail.com">
                  {' '}
                  Waliexchangehelpdesk@gmail.com{' '}
                </a>
                Thanks for your cooperation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default DepositProof
