import React, { useEffect, useState } from 'react'
import Footer from '../footer/Footer'
import Navbar from '../navbar/Navbar'
import axios from 'axios'
import { toast } from 'react-hot-toast'

const History = () => {
  const [data, setData] = useState([])
  async function getHistory() {
    try {
      const formData = new FormData()
      formData.append('cust_id', JSON.parse(localStorage.getItem('waliexchange'))?.cust_id ?? '1')

      var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://waliexchange.com/api2/public/index.php/wx_get_wallet_trans',
        data: formData,
      }
      const response = await axios(config)

      if (response.data.status === 200) {
        setData(response.data.msg)
      }
    } catch (err) {
      console.log(err)
      toast.error('There was an error while fetching History...')
    }
  }
  useEffect(() => {
    getHistory()
  }, [])

  return (
    <>
      <Navbar />
      <div>
        <h1 className="text-center" data-aos="fade-down" data-aos-delay="300">
          Transaction History
        </h1>
        {data.length === 0 ? (
          <h1>Loading...</h1>
        ) : (
          <div className="table-responsive table-div mt-3 pt-3 mb-3 mx-3" data-aos="fade-up" data-aos-delay="300">
            <table className="mb-3">
              <tr>
                <th className="text-blue">Wallet Address</th>
                <th className="text-blue">Transaction Type</th>
                <th className="text-blue">Date</th>
                <th className="text-blue">Amount</th>
              </tr>
              {data.map((row) => (
                <tr>
                  <td>{row.withdraw_btc_address}</td>
                  <td>{row.trans_type === '2' ? 'Withdraw' : 'Deposit'}</td>
                  <td>{row.created_at.slice(0, -9)}</td>
                  <td className={row.trans_type === '2' ? 'text-danger' : 'text-success'}>
                    <strong>${row.amt}</strong>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        )}
      </div>
      <Footer />
    </>
  )
}
export default History
