import React, { useState } from 'react'
import './styles/profile.css'
import profile_bg from '../images/candle-chart.jpg'
import { Link } from 'react-router-dom'
import Footer from '../components/footer/Footer'
import Navbar from '../components/navbar/Navbar'
import Subscription from './Subscription'
import { toast } from 'react-hot-toast'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

const Profile = () => {
  const [profileImage, setProfileImage] = useState('https://via.placeholder.com/150')
  const formData = new FormData()
  formData.append('cust_id', JSON.parse(localStorage.getItem('waliexchange'))?.cust_id ?? '35')

  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://waliexchange.com/api2/public/index.php/get_customer_details',
    data: formData,
  }

  const profileQuery = useQuery({
    queryKey: ['profile'],
    keepPreviousData: true,
    queryFn: () =>
      axios(config).then(function (response) {
        return response.data.msg
      }),
    onError: (err) => {
      console.log(err)
      if (err.response.status === 401) toast.error(err.response.data.result)
      else toast.error('There was an error while loading Profie Info.')
    },
  })
  const { data, isLoading, isError } = profileQuery
  console.log('data :', data)

  if (isLoading) {
    return <h1>Loading...</h1>
  }
  if (!data && isError) {
    return <h1>There was an Error, please try again later</h1>
  }

  const handleProfileImageChange = (e) => {
    const newImage = URL.createObjectURL(e.target.files[0])
    setProfileImage(newImage)
  }

  return (
    <>
      <Navbar />
      <div className="profile-page">
        <div className="container-fluid">
          <div className="row mt-5">
            <div className="col-lg-4 col-md-5 col-12 mb-3">
              <div className="card profile-card-3" data-aos="fade-up" data-aos-delay="300">
                <div className="background-block">
                  <img src={profile_bg} alt="profile-sample1" className="background" />
                </div>
                <div className="profile-thumb-block">
                  <img src={profileImage} alt="profile" className="profile" />
                  <span className="input-container">
                    <label htmlFor="profile">
                      <svg width={25} height={25} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.523 5.208a.6.6 0 0 1 0 .847L19.27 7.308l-2.4-2.4 1.252-1.253a.6.6 0 0 1 .848 0l1.552 1.552v.001Zm-2.1 2.947-2.4-2.4-8.176 8.177a.6.6 0 0 0-.145.235l-.966 2.897a.3.3 0 0 0 .38.38l2.896-.967a.6.6 0 0 0 .235-.144l8.176-8.178Z" />
                        <path fillRule="evenodd" d="M3.12 19.08a1.8 1.8 0 0 0 1.8 1.8h13.2a1.8 1.8 0 0 0 1.8-1.8v-7.2a.6.6 0 1 0-1.2 0v7.2a.6.6 0 0 1-.6.6H4.92a.6.6 0 0 1-.6-.6V5.88a.6.6 0 0 1 .6-.6h7.8a.6.6 0 1 0 0-1.2h-7.8a1.8 1.8 0 0 0-1.8 1.8v13.2Z" clipRule="evenodd" />
                      </svg>
                    </label>
                    <input className="d-none" id="profile" type="file" accept="image/*" onChange={handleProfileImageChange} />
                  </span>
                </div>
                <div className="card-content">
                  <h2>{data?.cust_full_name}</h2>
                  <p>
                    Current Balance: <strong>${data?.wallet_amt}</strong>
                  </p>
                </div>
              </div>
            </div>
            {/* Account Details */}
            <div className="col-lg-8 col-md-7 col-12 mb-3">
              <h2 className="text-blue p-scc-details-h text-center" data-aos="fade-down" data-aos-delay="300">
                Account Details
              </h2>
              <div className="row account-details" data-aos="fade-left" data-aos-delay="300">
                <div className="col-6">
                  <h5>
                    Balance: <strong>${data?.wallet_amt}</strong>
                  </h5>
                  <h5>
                    Total Spent: <strong>${data?.total_spent === null ? 0 : data?.total_spent}</strong>
                  </h5>
                  <h5>
                    Total Profit: <strong>${data?.total_withdraw}</strong>
                  </h5>
                  <h5>
                    Last Deposit: <strong className="text-success">${data?.last_deposit}</strong>
                  </h5>
                  <h5>
                    Last Withdrawn: <strong className="text-danger">${data?.last_withdraw}</strong>
                  </h5>
                </div>

                <div className="col-6">
                  <h5>Current Plan: {data?.current_plan === null ? <span className='text-success'>No Plan</span> : data?.current_plan}</h5>
                  <h5>
                    Plan Limit: <strong>$100 to ${data?.plan_limit}</strong>
                  </h5>
                  <h5>
                    Current Trade: <strong>$500</strong>
                  </h5>
                  <h5>
                    Running Profit: <strong>$100</strong>
                  </h5>
                </div>
              </div>
              <div className="d-flex text-align-middle profile-links justify-content-between">
                <div className="col-lg-2 col-md-4 col-4 py-2 text-center">
                  <Link className="btn-blue" to={'/deposit'}>
                    Deposit
                  </Link>
                </div>
                <div className="col-lg-2 col-md-4 col-4 py-2 text-center">
                  <Link className="btn-blue" to={'/withdraw'}>
                    Withdraw
                  </Link>
                </div>
                <div className="col-lg-2 col-md-4 col-4 py-2 text-center">
                  <Link className="btn-blue" to={'/history'}>
                    History
                  </Link>
                </div>
                <div className="col-lg-2 col-md-4 col-4 py-2 text-center">
                  <Link className="btn-blue" to={'/plans'}>
                    Plans
                  </Link>
                </div>
                <div className="col-lg-2 col-md-4 col-4 py-2 text-center">
                  <Link className="btn-blue" to={'/help'}>
                    Help
                  </Link>
                </div>
              </div>
              {/* <div className="row text-align-middle profile-links">
                <div
                  className="col-lg-2 col-md-4 col-4 py-2 text-center"
                  data-aos="fade-up"
                  data-aos-delay="300">
                  <Link className="btn-blue" to={"/deposit"}>
                    Deposit
                  </Link>
                </div>
                <div
                  className="col-lg-3 col-md-4 col-4 py-2 text-center"
                  data-aos="fade-up"
                  data-aos-delay="300">
                  <Link className="btn-blue" to={"/withdraw"}>
                    Withdraw
                  </Link>
                </div>

                <div
                  className="col-lg-3 col-md-4 col-4 py-2 text-center"
                  data-aos="fade-up"
                  data-aos-delay="300">
                  <Link className="btn-blue" to={"/plans"}>
                    Plans
                  </Link>
                </div>
                <div
                  className="col-lg-3 col-md-4 col-4 py-2 text-center"
                  data-aos="fade-up"
                  data-aos-delay="300">
                  <Link className="btn-blue" to={"/help"}>
                    Help
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row pt-5 pb-3">
            <div className="col-12">
              <Subscription />
            </div>
          </div>
        </div>
        {/* <div className="container">
          <div className="row pt-5 pb-3">
            <div className="col-12">
              <History />
            </div>
          </div>
        </div> */}
      </div>
      <Footer />
    </>
  )
}

export default Profile
