import React from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import "./deposit-withdraw.css";
import { NavLink } from 'react-router-dom';
import qr_code from '../../images/QR-code.jpg'
import ClipboardCopy from "./ClipboardCopy";


const Deposit = () => {
    return(
        <>
        <Navbar/>
           <div className="container-fluid deposit-page">
            <div className="row pt-5 pb-5 ">
              <div className="col-lg-6 col-md-8 col-11 mx-auto form-div p-4" data-aos="fade-up" data-aos-delay="300">
              <h2 className="text-center mb-0">Scan QR code</h2>
              <h3 className="text-center"><span className="small">or</span> <br /> Copy address below to deposit BITCOIN</h3>
              <div className="qr-code text-center mx-auto mt-5" 
              data-aos="fade-up" data-aos-delay="300"
              >
                <img height={250} width={250} style={{borderRadius:'10px'}} src={qr_code} alt="" />
              </div>
              <h6>Send only BITCOIN to this address, this address does not support other token.</h6>
              <div className="wallet-address">
                <h2 className="text-center mb-3">Wallet Address</h2>
                <ClipboardCopy copyText="3E7Y4nwCXx7LFxJPoT92zdAb3dbPjceJPk" />
              </div>
              </div>


            </div>
            <div className="row mb-4">
              <div className="col-lg-4 col-md-4 col-6 text-center mx-auto">
                <NavLink className='btn-blue w-100 px-5 py-2 fs-large' to="/deposit-proof">Continue</NavLink>
              </div>
            </div>
           </div>
           <Footer/>
        </>
    );
}
export default Deposit;
