import React, { useEffect, useState } from 'react'
import Footer from '../footer/Footer'
import Navbar from '../navbar/Navbar'
import './deposit-withdraw.css'
import { toast } from 'react-hot-toast'
import axios from 'axios'

const Withdraw = () => {
  const [amount, setAmount] = useState('')
  const [address, setAddress] = useState('')
  const [loading, setLoading] = useState('')

  useEffect(() => {
    setAddress(JSON.parse(localStorage.getItem('waliexchange'))?.btc_address ?? '')
  }, [])

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()

    if (!amount || !address) {
      toast.error('Please Fill up all fields')
      return
    }

    try {
      const formData = new FormData()
      formData.append('cust_id', JSON.parse(localStorage.getItem('waliexchange'))?.cust_id ?? '1')
      formData.append('withdraw_amt', amount)
      formData.append('to_btc_address', address)

      var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://waliexchange.com/api2/public/index.php/wx_genWithdrawal_request',
        data: formData,
      }
      const response = await axios(config)

      if (response.data.status === 200) {
        toast.success(response.data.msg)
      }
    } catch (err) {
      console.log(err)
      toast.error('There was an error while withdrawing...')
    }

    setLoading(false)
  }

  return (
    <>
      <Navbar />
      <div className="container-fluid deposit-page">
        <div className="row pt-5 pb-5 ">
          <div className="col-lg-6 col-md-8 col-11 mx-auto form-div p-4" data-aos="fade-up" data-aos-delay="300">
            <h1 className="text-center mb-3" data-aos="fade-down" data-aos-delay="300">
              Withdraw
            </h1>
            <form onSubmit={handleSubmit} className="form-deposit">
              <input type="text" placeholder="Wallet Address" value={address} onChange={(e) => setAddress(e.target.value)} />
              <input type="number" placeholder="$ Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
              <button type="submit" disabled={loading} className="btn btn-blue px-5">
                Widthdraw
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default Withdraw
